import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useRouter } from 'vue-router'

export default _defineComponent({
  expose: [],
  setup(__props) {

useRouter().replace('/trials')

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock("div"))
}
}

})