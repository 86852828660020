import { computed, ComputedRef } from 'vue'
import { useReactiveFactory } from '@/reactives/factory'

type UseToggle = {
  state: ComputedRef<boolean>
  setState: (payload: boolean) => void
  toggle: () => void
}

const useToggle = (init = false): UseToggle => {
  const { state: _state, setState } = useReactiveFactory(init)

  const toggle = (): void => setState(!_state.value)

  return {
    state: computed<boolean>(() => _state.value),
    setState,
    toggle,
  }
}
export { useToggle }
