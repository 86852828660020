import type { CognitoUser } from '@aws-amplify/auth'

const DEFAULT_TIMEOUT = 5000
const DEFAULT_STATUS = 'error'

type Status = 'success' | 'info' | 'warning' | 'error'
type NoticeOptions = {
  status: Status
  message: string
  timeout: number
  autoClose: boolean
}

type State = {
  user?: CognitoUser
  message: NoticeOptions['message']
  timeout: NoticeOptions['timeout']
  status: NoticeOptions['status']
  autoClose: NoticeOptions['autoClose']
  isOpen: boolean
  timeoutId?: NodeJS.Timeout
  isRetrying: boolean
  retryTimeoutId?: NodeJS.Timeout
}
const state: State = {
  user: undefined,
  message: '',
  timeout: DEFAULT_TIMEOUT,
  status: DEFAULT_STATUS,
  autoClose: true,
  isOpen: false,
  timeoutId: undefined,
  isRetrying: false,
  retryTimeoutId: undefined,
}

export { state, State, Status, NoticeOptions, DEFAULT_TIMEOUT, DEFAULT_STATUS }
