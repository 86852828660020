import { createApp } from 'vue'
import { router } from '@/router'
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import '@/registerServiceWorker'
import '@/assets/styles/tailwind.scss'
import '@/index.scss'

import App from '@/App.vue'
import store from '@/store'
import amplify from '@/plugins/amplify'
import sentry from '@/plugins/sentry'
import ky from '@/plugins/ky'
import i18n from '@/plugins/i18n'
import hub from '@/plugins/hub'

createApp(App)
  .use(store)
  .use(router)
  .use(sentry)
  .use(amplify)
  .use(ky)
  .use(i18n)
  .use(hub)
  .use(MonthPicker)
  .use(MonthPickerInput)
  .mount('#app')
