import { ComputedRef } from 'vue'
import { useToggle } from '@/reactives/toggle'

type UseProgress = {
  isProgress: ComputedRef<boolean>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  safeCall: (fn: Function | Promise<any>) => Function | Promise<any> | undefined
  start: () => void
  done: () => void
}

const useProgress = (defaultValue = false): UseProgress => {
  const { state: isProgress, setState: setProgress } = useToggle(defaultValue)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const safeCall = (fn: Function | Promise<any>) => {
    if (isProgress.value) return
    setProgress(true)
    return fn
  }
  const start = (): void => setProgress(true)
  const done = (): void => setProgress(false)
  return {
    isProgress,
    safeCall,
    start,
    done,
  }
}

export { useProgress }
