/* eslint-disable */
import { Plugin } from 'vue'
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader'
import Amplify, { I18n } from '@aws-amplify/core'
import locales from '@/locales/amplify.json'

const awsmobile = {
  aws_cognito_region: process.env.VUE_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.VUE_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.VUE_APP_AWS_USER_POOL_APP_CLIENT_ID,
}

const plugin: Plugin = {
  install: () => {
    I18n.putVocabularies(locales)
    if (window) {
      I18n.setLanguage(window.navigator.language === 'en' ? 'en' : 'ja')
    }
    applyPolyfills().then(() => {
      defineCustomElements(window)
    })
    Amplify.configure(awsmobile)
  },
}

export default plugin
