import ky from 'ky'
import { Plugin, inject, InjectionKey } from 'vue'
import { useStore } from '@/store'
import { MutationType } from '@/store/mutations'
import { getIdToken } from '@/functions/pure/auth'
type Http = typeof ky
const key: InjectionKey<Http> = Symbol('$http')
const useKy = (): { http: typeof ky } => {
  return { http: inject(key) || ky }
}

const http = ky.create({
  prefixUrl: process.env.VUE_APP_BASE_API_URL,
  timeout: 30000,
  retry: {
    limit: 7,
    methods: ['get', 'post', 'patch', 'put'],
    statusCodes: [429],
  },
  hooks: {
    beforeRetry: [
      () => {
        useStore().commit(MutationType.SetRetrying, true)
      },
    ],
    beforeRequest: [
      (request) => {
        const user = useStore().state.user
        if (user) {
          const idToken = getIdToken(user)

          request.headers.set('Authorization', `Bearer ${idToken}`)
        }
      },
    ],
  },
})

const plugin: Plugin = {
  install: (app) => {
    app.provide(key, http)
  },
}

export default plugin
export { useKy, Http, http }
