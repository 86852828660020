import { useReactiveFactory } from '@/reactives/factory'
import { useToggle } from '@/reactives/toggle'
import { UnwrapRef } from 'vue'

const useTimeout = () => {
  const { state, setState } = useReactiveFactory<number | undefined>(undefined)

  const _setTimeout = (fn: Function, timeout: number): void => {
    setState(setTimeout(fn, timeout))
  }

  const _clearTimeout = (): void => {
    clearTimeout(state.value)
  }

  return { _setTimeout, _clearTimeout }
}

const useMiltipleDialog = <T extends string>() => {
  const { state: dialog, setState: changeDialog } = useToggle()
  const { state, setState, resetState } = useReactiveFactory<T>('' as T)
  const { _setTimeout, _clearTimeout } = useTimeout()

  const show = (candidate: UnwrapRef<T>): void => {
    _clearTimeout()
    setState(candidate)
    changeDialog(true)
  }

  const hide = (buffer = 200): void => {
    changeDialog(false)
    _setTimeout(resetState, buffer)
  }

  return { show, hide, dialog, candidate: state }
}

export { useMiltipleDialog }
