import type { Status as MessageStatus } from '@/store/state'
import type { CustomerStatus } from '@/types'
import { defineAsyncComponent } from 'vue'

const getStatusIcon = (status: MessageStatus) => {
  switch (status) {
    case 'error': {
      return defineAsyncComponent(
        () => import('@/components/base/icons/mdi/MdiAlert.vue')
      )
    }

    case 'success': {
      return defineAsyncComponent(
        () => import('@/components/base/icons/mdi/MdiCheckCircle.vue')
      )
    }
  }
}

const getStatusColor = (status: MessageStatus) => {
  switch (status) {
    case 'error': {
      return 'text-red-500 '
    }

    case 'success': {
      return 'text-green-500'
    }
  }
}

const classStatus = (payload: CustomerStatus): string => {
  switch (payload) {
    case 'unapproved': {
      return 'text-yellow-800 bg-yellow-100'
    }

    case 'trial': {
      return 'text-blue-800 bg-blue-100'
    }

    case 'refused': {
      return 'text-red-800 bg-red-100'
    }

    case 'beta': {
      return 'text-white bg-black'
    }
  }
}

export { getStatusIcon, getStatusColor, classStatus }
