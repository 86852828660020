import { NavigationGuardWithThis } from 'vue-router'
import { setUser } from '@/functions/side-effects/auth'
import { AUTH_PATH } from '@/constants'

const authGuard: NavigationGuardWithThis<undefined> = async (
  { path, meta },
  _,
  next
) => {
  if (path === AUTH_PATH || !meta.requiresAuth) {
    next()
    return
  }

  const user = await setUser()
  if (user) {
    next()
  } else {
    next({
      path: AUTH_PATH,
    })
  }
}

export { authGuard, AUTH_PATH }
