import Auth from '@aws-amplify/auth'
import { useStore } from '@/store'
import { MutationType } from '@/store/mutations'
import { CognitoUser } from 'amazon-cognito-identity-js'
const getUser = (): Promise<CognitoUser | undefined> =>
  Auth.currentAuthenticatedUser().catch(() => undefined)
const setUser = async (): Promise<CognitoUser | undefined> => {
  const user = await getUser()
  if (user) {
    useStore().commit(MutationType.SetUser, user)
  } else {
    useStore().commit(MutationType.SetUser, undefined)
  }

  return user
}

export { getUser, setUser }
