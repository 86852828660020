<template>
  <div class="flex flex-col lg:flex-row h-screen bg-gray-100">
    <the-navigation-drawer
      class="fixed inset-y-0 w-56 left-0 transform-gpu duration-300 transition-all overflow-y-auto lg:translate-x-0 lg:static lg:inset-0 -translate-x-full ease-in"
    ></the-navigation-drawer>

    <the-header
      class="h-12 static rounded-bl-5xl inset-x-0 top-0 w-full transition delay-500 duration-700 lg:-translate-y-full transform lg:fixed -translate-y-0"
      @open="setNav(true)"
    />

    <div class="flex-1 flex flex-col overflow-hidden">
      <main class="flex-1 overflow-x-hidden overflow-y-auto">
        <div class="container mx-auto px-2 sm:px-6 py-1 sm:mb-32">
          <router-view />
        </div>
      </main>
    </div>
  </div>

  <transition name="slide-right">
    <hover-navigation v-if="isNav" class="max-w-9/12" @close="setNav(false)">
      <nav-contents :contents="contents" />
    </hover-navigation>
  </transition>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue'
import TheNavigationDrawer from '@/components/app/TheNavigationDrawer.vue'
import TheHeader from '@/components/app/TheHeader.vue'
import NavContents, { Content } from '@/components/app/NavContents.vue'
import { useI18n } from 'vue-i18n'

import { useToggle } from '@/reactives'

export default defineComponent({
  components: {
    TheHeader,
    NavContents,
    TheNavigationDrawer,
    HoverNavigation: defineAsyncComponent(
      () => import('@/components/app/HoverNavigation.vue')
    ),
  },

  setup() {
    const { state: isNav, setState: setNav } = useToggle()
    const { t } = useI18n()
    const contents: Content[] = [
      {
        title: t('customer'),
        className: 'text-white capitalize text-sm mb-2',
      },
      {
        title: t('trial'),
        activeClass: 'bg-gray-900 text-green-400',
        className:
          'shadow block text-lg transition duration-200 rounded hover:bg-gray-900 px-2 capitalize py-1',
        to: '/trials',
      },
    ]

    return {
      isNav,
      setNav,
      contents,
      t,
    }
  },
})
</script>

<style lang="scss" src="@/assets/styles/transition.scss"></style>
