<template>
  <div class="bg-gray-800 text-white shadow-md flex flex-col flex-shrink-0">
    <div class="px-3 py-5">
      <logo-letter class="text-xl" />
    </div>

    <nav-contents class="p-3" :contents="contents" />
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n'
import LogoLetter from '@/components/app/LogoLetter.vue'
import NavContents from '@/components/app/NavContents.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    LogoLetter,
    NavContents,
  },

  setup() {
    const { t } = useI18n()
    const contents = [
      {
        content: {
          title: t('customer'),
          className: 'text-gray-300 capitalize text-sm mb-2',
        },
      },

      {
        content: {
          title: t('trial'),
          activeClass: 'bg-gray-900',
          className:
            'shadow block text-lg hover:text-green-400 transition duration-200 rounded hover:bg-gray-900 px-2 capitalize py-1',
          to: '/trials',
        },
      },
      {
        content: {
          title: t('production'),
          activeClass: 'bg-gray-900',
          className:
            'shadow block text-lg hover:text-green-400 transition duration-200 rounded hover:bg-gray-900 px-2 capitalize py-1',
          to: '/customers/services',
        },
      },
      {
        content: {
          title: t('billings'),
          activeClass: 'bg-gray-900',
          className:
            'shadow block text-lg hover:text-green-400 transition duration-200 rounded hover:bg-gray-900 px-2 capitalize py-1',
          to: '/customers/billings',
        },
      },
      {
        content: {
          title: t('aggregate'),
          activeClass: 'bg-gray-900',
          className:
            'shadow block text-lg  transition duration-200 rounded  px-2 capitalize py-1',
          // to: '/aggregate/sales',
        },
        children: [
          {
            title: t('sales'),
            activeClass: 'bg-gray-900',
            className:
              'shadow block text-lg hover:text-green-400 transition duration-200 rounded hover:bg-gray-900 px-8 capitalize py-1',
            to: '/aggregate/sales',
          },
          {
            title: t('purchase'),
            activeClass: 'bg-gray-900',
            className:
              'shadow block text-lg hover:text-green-400 transition duration-200 rounded hover:bg-gray-900 px-8 capitalize py-1',
            to: '/aggregate/purchase',
          },
        ],
      },
    ]
    return {
      t,
      contents,
    }
  },
})
</script>
