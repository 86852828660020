<template>
  <nav class="p-3">
    <template v-for="{ content, children } in contents" :key="content.title">
      <nav-content :content="content" />
      <template v-if="children != null">
        <template v-for="child in children" :key="child.title">
          <nav-content :content="child" />
        </template>
      </template>
    </template>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NavContent from './NavContent.vue'

export type Content = {
  title: string
  to?: string
  className?: string
  activeClass?: string
}

export type Props = {
  content: Content
  children?: Content[]
}

export default defineComponent({
  components: {
    NavContent,
  },
  props: {
    contents: {
      type: Array as () => Props[],
      default: () => [],
    },
  },
})
</script>
