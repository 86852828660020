<template>
  <h3 v-if="!content.to" :class="content.className">
    {{ content.title }}
  </h3>
  <router-link
    v-else
    :active-class="content.activeClass"
    :class="content.className"
    class="shadow block text-lg hover:text-green-400 transition duration-200 rounded hover:bg-gray-900 px-2 capitalize py-1"
    :to="content.to"
    >{{ content.title }}</router-link
  >
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export type Content = {
  title: string
  to?: string
  className?: string
  activeClass?: string
}

export default defineComponent({
  props: {
    content: {
      type: Object as () => Content,
      default: () => undefined,
    },
  },
})
</script>
