<template>
  <router-view />

  <base-dialog
    to="body"
    class-dialog="backdrop-blur-2 bg-black bg-opacity-70"
    :model-value="isRetrying"
  >
    <template #card>
      <div class="text-center text-white">
        <mdi-loading class="animate-spin w-16 h-16 sm:w-32 sm:h-32" />

        <p>{{ t('alert') }}</p>
      </div>
    </template>
  </base-dialog>
  <teleport to="body">
    <transition name="slide-left">
      <base-snackbar
        v-if="isOpen"
        class="fixed z-10 bottom-6 min-w-40 max-w-xs left-6"
        :message="message"
        :class-icon="iconColor"
        :icon="icon"
        @close="resetNotice"
      />
    </transition>
  </teleport>
</template>

<script lang="ts">
import { useStore } from '@/store'
import { computed, defineComponent, defineAsyncComponent } from 'vue'
import { ActionTypes } from '@/store/actions'
import { getStatusColor, getStatusIcon } from '@/functions/pure/utils'
import { useI18n } from 'vue-i18n'
const { dispatch, state } = useStore()

export default defineComponent({
  components: {
    BaseSnackbar: defineAsyncComponent(
      () => import('@/components/base/BaseSnackbar.vue')
    ),
    BaseDialog: defineAsyncComponent(
      () => import('@/components/base/BaseDialog.vue')
    ),
    MdiLoading: defineAsyncComponent(
      () => import('@/components/base/icons/mdi/MdiLoading.vue')
    ),
  },

  setup() {
    const { t } = useI18n()
    return {
      t,
      isOpen: computed(() => state.isOpen),
      message: computed(() => state.message),
      icon: computed(() => getStatusIcon(state.status)),
      iconColor: computed(() => getStatusColor(state.status)),
      isRetrying: computed(() => state.isRetrying),
      resetNotice: () => dispatch(ActionTypes.ResetNotice),
    }
  },
})
</script>

<i18n lang="yml">
en:
  alert: This can take a few minutes
ja:
  alert: これには数分かかることがあります
</i18n>

<style lang="scss" src="@/assets/styles/transition.scss"></style>
