
import LogoLetter from '@/components/app/LogoLetter.vue'
import MdiMenu from '@/components/base/icons/mdi/MdiMenu.vue'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    LogoLetter,
    MdiMenu,
  },
  emits: ['open'],

  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
})
