import { RouteRecordRaw } from 'vue-router'
import Index from '@/views/index.vue'
import Default from '@/layouts/default.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Index,
    meta: { requiresAuth: true },
  },

  {
    path: '/',
    component: Default,
    children: [
      {
        path: '/trials',
        component: () => import('@/views/Trials.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/customers/services',
        component: () => import('@/views/CustomersServices.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/customers/billings',
        component: () => import('@/views/Billings.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/aggregate/sales',
        component: () => import('@/views/Aggregate/Sales.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/aggregate/purchase',
        component: () => import('@/views/Aggregate/Purchase.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/signin',
    component: () => import('@/views/SignIn.vue'),
    meta: { requiresAuth: false },
  },
  { path: '/:pathMatch(.*)*', component: () => import('@/views/404.vue') },
]

export default routes
