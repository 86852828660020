
import { defineAsyncComponent, defineComponent } from 'vue'
import TheNavigationDrawer from '@/components/app/TheNavigationDrawer.vue'
import TheHeader from '@/components/app/TheHeader.vue'
import NavContents, { Content } from '@/components/app/NavContents.vue'
import { useI18n } from 'vue-i18n'

import { useToggle } from '@/reactives'

export default defineComponent({
  components: {
    TheHeader,
    NavContents,
    TheNavigationDrawer,
    HoverNavigation: defineAsyncComponent(
      () => import('@/components/app/HoverNavigation.vue')
    ),
  },

  setup() {
    const { state: isNav, setState: setNav } = useToggle()
    const { t } = useI18n()
    const contents: Content[] = [
      {
        title: t('customer'),
        className: 'text-white capitalize text-sm mb-2',
      },
      {
        title: t('trial'),
        activeClass: 'bg-gray-900 text-green-400',
        className:
          'shadow block text-lg transition duration-200 rounded hover:bg-gray-900 px-2 capitalize py-1',
        to: '/trials',
      },
    ]

    return {
      isNav,
      setNav,
      contents,
      t,
    }
  },
})
