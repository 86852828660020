<template>
  <header
    class="bg-gray-800 px-3 py-1 flex flex-none items-center justify-between"
  >
    <logo-letter class="text-white text-lg" />

    <button
      class="mr-2 rounded-full outline-none shadow focus:outline-none"
      :title="t('title')"
      @click="$emit('open')"
    >
      <mdi-menu class="text-white" />
    </button>
  </header>
</template>

<script lang="ts">
import LogoLetter from '@/components/app/LogoLetter.vue'
import MdiMenu from '@/components/base/icons/mdi/MdiMenu.vue'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    LogoLetter,
    MdiMenu,
  },
  emits: ['open'],

  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
})
</script>

<i18n lang="yml">
en:
  title: Menu
ja:
  title: メニュー
</i18n>
