import { ActionContext, ActionTree } from 'vuex'
import { Mutations, MutationType } from '@/store/mutations'
import {
  State,
  DEFAULT_TIMEOUT,
  DEFAULT_STATUS,
  NoticeOptions,
} from '@/store/state'

const wait = (milliseconds: number) =>
  new Promise<void>((resolve) =>
    setTimeout(() => {
      resolve()
    }, milliseconds)
  )

enum ActionTypes {
  SetNotice = 'SET_NOTICE',
  ResetNotice = 'RESET_NOTICE',
}

type ActionAugments = Omit<ActionContext<State, State>, 'commit'> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

type Actions = {
  [ActionTypes.SetNotice](
    context: ActionAugments,
    noticeOptions: Partial<NoticeOptions>
  ): void
  [ActionTypes.ResetNotice](context: ActionAugments): void
}

const actions: ActionTree<State, State> & Actions = {
  [ActionTypes.SetNotice](
    { commit, state, dispatch },
    { status, message, autoClose, timeout }
  ) {
    if (status) commit(MutationType.SetStatus, status)
    if (message) commit(MutationType.SetMessage, message)
    if (autoClose) commit(MutationType.SetAutoClose, autoClose)
    if (timeout) commit(MutationType.SetTimeout, timeout)
    commit(MutationType.SetIsOpen, true)

    if (state.timeoutId) {
      clearTimeout(state.timeoutId)
      commit(MutationType.SetTimeoutId, undefined)
    }

    if (state.autoClose) {
      const timeoutId = setTimeout(() => {
        dispatch(ActionTypes.ResetNotice)
      }, state.timeout)
      commit(MutationType.SetTimeoutId, timeoutId)
    }
  },

  async [ActionTypes.ResetNotice]({ commit }) {
    commit(MutationType.SetIsOpen, false)
    await wait(0)
    commit(MutationType.SetStatus, DEFAULT_STATUS)
    commit(MutationType.SetMessage, '')
    commit(MutationType.SetTimeout, DEFAULT_TIMEOUT)
    commit(MutationType.SetAutoClose, true)
  },
}

export { actions, Actions, ActionTypes }
