import { createRouter, createWebHistory } from 'vue-router'
import { authGuard } from '@/router/authenticator'
import routes from '@/router/routes'
import NProgress from 'nprogress'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeResolve(authGuard)
router.beforeEach(() => {
  NProgress.start()
})
router.afterEach(() => {
  NProgress.done()
})
export { router }
