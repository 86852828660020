
import { useI18n } from 'vue-i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const { t } = useI18n()

    return { t }
  },
})
