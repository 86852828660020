import { Plugin } from 'vue'
import { Hub, HubCallback } from '@aws-amplify/core'
import { AUTH_PATH, DEFAULT_PATH } from '@/constants'
import { router } from '@/router'
import { setUser } from '@/functions/side-effects/auth'

const callback: HubCallback = async ({ payload }) => {
  await setUser()
  switch (payload.event) {
    case 'signIn': {
      router.push({ path: DEFAULT_PATH })
      break
    }
    case 'signOut': {
      router.push({ path: AUTH_PATH })
    }
  }
}

const plugin: Plugin = {
  install: () => {
    Hub.listen('auth', callback)
  },
}

export default plugin
