import { ref, UnwrapRef, Ref } from 'vue'

type ReactiveFactory<T> = {
  state: Ref<UnwrapRef<T>>
  setState: (v: UnwrapRef<T>) => void
  resetState: (v?: UnwrapRef<T> | undefined) => void
}

const useReactiveFactory = <T>(init: T): ReactiveFactory<T> => {
  const state = ref<T>(init)

  const setState = (v: UnwrapRef<T>): void => {
    state.value = v
  }

  const resetState = (v?: UnwrapRef<T>): void => {
    state.value = v || (init as UnwrapRef<T>)
  }
  return { state, setState, resetState }
}

export { useReactiveFactory }
