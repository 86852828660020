
import { defineComponent } from 'vue'

export type Content = {
  title: string
  to?: string
  className?: string
  activeClass?: string
}

export default defineComponent({
  props: {
    content: {
      type: Object as () => Content,
      default: () => undefined,
    },
  },
})
