import { MutationTree } from 'vuex'
import { State, Status, NoticeOptions } from '@/store/state'
import type { CognitoUser } from '@aws-amplify/auth'

enum MutationType {
  SetUser = 'SET_USER',
  SetStatus = 'SET_STATUS',
  SetMessage = 'SET_MESSAGE',
  SetTimeout = 'SET_TIMEOUT',
  SetAutoClose = 'SET_AUTO_CLOSE',
  SetIsOpen = 'SET_IS_OPEN',
  SetTimeoutId = 'SET_TIMEOUT_ID',
  SetRetrying = 'SET_RETRYING',
}

type Mutations = {
  [MutationType.SetUser](state: State, user?: CognitoUser): void
  [MutationType.SetStatus](state: State, status: Status): void
  [MutationType.SetMessage](
    state: State,
    message: NoticeOptions['message']
  ): void
  [MutationType.SetTimeout](
    state: State,
    timeout: NoticeOptions['timeout']
  ): void
  [MutationType.SetAutoClose](
    state: State,
    autoClose: NoticeOptions['autoClose']
  ): void
  [MutationType.SetIsOpen](state: State, isOpen: boolean): void
  [MutationType.SetTimeoutId](state: State, timeoutId?: NodeJS.Timeout): void
  [MutationType.SetRetrying](state: State, isRetry: boolean): void
}

const mutations: MutationTree<State> & Mutations = {
  [MutationType.SetUser](state, user?) {
    state.user = user
  },
  [MutationType.SetStatus](state, status) {
    state.status = status
  },
  [MutationType.SetMessage](state, message) {
    state.message = message
  },
  [MutationType.SetTimeout](state, timeout) {
    state.timeout = timeout
  },
  [MutationType.SetAutoClose](state, autoClose) {
    state.autoClose = autoClose
  },
  [MutationType.SetIsOpen](state, isOpen) {
    state.isOpen = isOpen
  },
  [MutationType.SetTimeoutId](state, timeoutId?) {
    state.timeoutId = timeoutId
  },
  [MutationType.SetRetrying](state, isRetrying) {
    if (state.retryTimeoutId) {
      clearTimeout(state.retryTimeoutId)
    }
    state.retryTimeoutId = setTimeout(() => {
      state.isRetrying = false
    }, 10000)
    state.isRetrying = isRetrying
  },
}

export { mutations, Mutations, MutationType }
