<template>
  <h2 class="capitalize">
    {{ t('project_name') }}
    <span class="bg-yellow-500 shadow rounded p-1">{{ t('admin') }}</span>
  </h2>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const { t } = useI18n()

    return { t }
  },
})
</script>
