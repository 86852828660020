
import { defineComponent } from 'vue'
import NavContent from './NavContent.vue'

export type Content = {
  title: string
  to?: string
  className?: string
  activeClass?: string
}

export type Props = {
  content: Content
  children?: Content[]
}

export default defineComponent({
  components: {
    NavContent,
  },
  props: {
    contents: {
      type: Array as () => Props[],
      default: () => [],
    },
  },
})
