import { render } from "./App.vue?vue&type=template&id=6560389c"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"

import "@/assets/styles/transition.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* custom blocks */
import block0 from "./App.vue?vue&type=custom&index=0&blockType=i18n&lang=yml"
if (typeof block0 === 'function') block0(script)


export default script