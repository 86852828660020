
import { useStore } from '@/store'
import { computed, defineComponent, defineAsyncComponent } from 'vue'
import { ActionTypes } from '@/store/actions'
import { getStatusColor, getStatusIcon } from '@/functions/pure/utils'
import { useI18n } from 'vue-i18n'
const { dispatch, state } = useStore()

export default defineComponent({
  components: {
    BaseSnackbar: defineAsyncComponent(
      () => import('@/components/base/BaseSnackbar.vue')
    ),
    BaseDialog: defineAsyncComponent(
      () => import('@/components/base/BaseDialog.vue')
    ),
    MdiLoading: defineAsyncComponent(
      () => import('@/components/base/icons/mdi/MdiLoading.vue')
    ),
  },

  setup() {
    const { t } = useI18n()
    return {
      t,
      isOpen: computed(() => state.isOpen),
      message: computed(() => state.message),
      icon: computed(() => getStatusIcon(state.status)),
      iconColor: computed(() => getStatusColor(state.status)),
      isRetrying: computed(() => state.isRetrying),
      resetNotice: () => dispatch(ActionTypes.ResetNotice),
    }
  },
})
